import React, { Component } from 'react'
import styled, { css, keyframes } from 'styled-components'
import Col from './Col'
import Row from './Row'
import Gap from './Gap'
import Text, { TextComponent } from './Text'
import Section from './Section'
import BgImage from './BgImage'
import Video from './Video'
import VH from './VH'
import RH from './RH'
import VideoPopup from './VideoPopup'
import HpTopCarousel from './HpTopCarousel'
import { MuteIcon, UnmuteIcon } from './icons'

import { up, addPagePadding, theme } from '../lib/styles'

import youtubeIcon from '../data/images/hp/youtube-icon-red.png'

import introVideo from '../data/videos/intro.mp4'
// import islandHoppingVideo from '../data/videos/island-hopping.mp4'
import zuri2IntroVideo from '../data/videos/ZURI_2_0_intro.mp4'

import HpTopIcons from './HpTopIcon'

const HiddenTitle = styled.h1`
  display: none;
`

const HpTopText = styled(Text)`
  ${({ theme: { colors } }) => css`
    color: ${colors.textWhite};
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;

    ${up('mobile')} {
      font-size: 30px;
      line-height: 38px;
    }
  `}
`
const Content = styled.div`
  z-index: 1;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  padding-top: 40px;
  padding-bottom: 40px;

  padding-left: 8px;
  padding-right: 8px;

  padding-bottom: 20px;

  height: ${theme.hpTopSectionMobileHeight};
  max-height: ${theme.hpTopSectionMaxHeight};

  ${up(theme.hpTopSectionBp)} {
    height: 100vh;

    ${addPagePadding()}
    padding-top: 120px;
    padding-bottom: 30px;
  }

  ${up('tablet')} {
    top: 50%;
    display: block;
    height: auto;

    height: 100vh;
    display: flex;
    padding-top: 120px;
    padding-bottom: 30px;
    justify-content: space-around;
    justify-content: space-between;
  }
  ${up('largeDesktop')} {
    padding-bottom: 60px;
  }
`

const MobileIconsWrapper = styled(Col)`
 ${addPagePadding()}
 padding-top: 30px;
 padding-bottom: 40px;
 z-index: 0;
  ${up('desktop')} {
    display: none;
  }
`

class HpTop extends Component {
  constructor(props) {
    super(props)
    this.introVideoRef = React.createRef()
    this.islandHoppingVideoRef = React.createRef()
    this.videoPopupRef = React.createRef()
    this.state = {
      isVideoPlaying: false,
      isMuted: false,
      activeItem: -1,
      progress: 0,
    }
  }

  render() {
    const { image, images } = this.props
    const { isVideoPlaying, isMuted, activeItem, progress } = this.state

    return (
      <>
        <Section.FullHeightTop>
          <HpTopCarousel images={images} />


          <VH show={!isVideoPlaying}>
            <Content>
              <Col />
      
              <RH hideBelow={1023}>
                <Col>
                  <HpTopText as="div">Advanced Regional Air Mobility with Hybrid VTOL</HpTopText>
                  <Gap gap="40px" mobileGap="20px" bp="largeDesktop" />
                  <HpTopIcons />
                </Col>
              </RH>

            </Content>
          </VH>
        </Section.FullHeightTop>

        <MobileIconsWrapper>
          <HpTopText as="h1">Advanced Regional Air Mobility with Hybrid VTOL</HpTopText>
          <Gap gap="40px" mobileGap="20px" bp="largeDesktop" />
          <HpTopIcons />
        </MobileIconsWrapper>
      </>
    )
  }
}

export default HpTop
