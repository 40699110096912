import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react'
import { Row, Col, Gap, Text, Title, Link, YouTube, Section } from '.'
import { up } from '../lib/styles'

const POST_BP = 'tablet'

const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
const getProcessingInstructions = (images) => [
  // youtube video
  {
    shouldProcessNode(node) {
      const { type, data, name, children } = node
      if (
        type === 'tag' &&
        name === 'p' &&
        children &&
        children[0] &&
        children[0].data
      ) {
        return children[0].data.startsWith('[[video ')
      }
      return false
    },
    processNode(node, children) {
      const videoCode = children[0].split('[[video ')[1].split(']]')[0]
      return (
        <Fragment key={videoCode}>
          <Gap gap="12px" />
          <YouTube videoId={videoCode} />
          <Gap gap="60px" />
        </Fragment>
      )
    },
  },
  {
    // Custom <img> processing
    shouldProcessNode(node) {
      return node.name === 'img'
    },
    processNode(node, children, index) {
      const foundImage = images.find(
        (image) =>
          node.attribs.src &&
          node.attribs.src.indexOf(image.relativePath) !== -1,
      )
      if (foundImage && foundImage.contentImage) {
        return (
          <Col maxWidth="100%" key={node.attribs.src + index}>
            <Gap gap="8px" mobileGap="8px" />
            <Img alt={node.attribs.alt} fluid={foundImage.contentImage.fluid} />
            <Gap gap="40px" mobileGap="16px" />
          </Col>
        )
      }
      return <img {...node.attribs} />
    },
  },
  {
    // Table processing
    shouldProcessNode(node) {
      return node.name === 'table'
    },
    processNode(node, children, index) {
      return (
        <FullWidthContainer>
          <TableWrapper>
            <table {...node.attribs}>{children}</table>
          </TableWrapper>
        </FullWidthContainer>
      )
    },
  },
  {
    // Anything else
    shouldProcessNode(node) {
      return true
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
]
const htmlToReactParser = new HtmlToReactParser()

const CONTENT_WIDTH = '1012px'

const TableWrapper = styled.div`
  overflow-x: auto;
  margin: 0 auto 40px auto;
  padding: 0 20px;
`

const FullWidthContainer = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Ensure it doesn't create horizontal scrollbars */
  overflow-x: hidden;
`

const PostWrapper = ({ children }) => (
  <Row justifyContent="center">
    <Col
      maxWidth={CONTENT_WIDTH}
      mobileMaxWidth="600px"
      bp="tablet"
      alignItems="center"
    >
      <Text textAlign="justify" mobileTextAlign="left">
        {children}
      </Text>
    </Col>
  </Row>
)

const Content = styled.div`
  font-size: 24px;
  color: black;

  /* img {
    width: 100%;
    height: auto;
    margin: 18px 0;
  } */

  li,
  p {
    color: #8d8d8f;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    ${up(POST_BP)} {
      font-size: 24px;
      line-height: 33px;
    }
  }
  p {
    margin: 0 0 24px 0;
  }

  ol,
  ul {
    margin: 0 0 16px 0;
  }

  strong {
    color: black;
  }

  h2 {
    display: block;
    color: black;
    text-align: center;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 700;
    line-height: 33px;
    margin: 0 auto 8px auto;
    padding: 0;

    ${up('mobile')} {
      font-size: 36px;
      font-weight: 400;
      margin: 0 auto 32px auto;
    }
  }

  li {
    margin-bottom: 16px;
  }

  h3 {
    display: block;
    color: black;
    text-transform: uppercase;
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    margin: 0 0 8px 0;
    padding: 0;
    ${up('mobile')} {
      font-size: 28px;
      margin: 0 0 24px 0;
      padding: 0;
    }
  }

  a {
    text-decoration: underline;
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #8d8d8f;
    text-align: left;

    font-size: 14px;
    line-height: 1.2;
    color: #8d8d8f;
    font-weight: 400;

    td,
    th {
      padding: 8px 8px 6px 8px;
      border: 1px solid #8d8d8f;
    }

    th {
      color: #000;
      background-color: #d2e7f8;
    }

    ${up('mobile')} {
      font-size: 18px;
    }

    ${up('tablet')} {
      td,
      th {
        padding: 10px 12px 8px 12px;
      }
    }
  }
`

const PageContent = ({ page, contentImages }) => {
  const content = page.content
  const parsedContent = htmlToReactParser.parseWithInstructions(
    content,
    () => true,
    getProcessingInstructions(contentImages),
  )
  return (
    <>
      <Section>
        <Gap gap="80px" mobileGap="40px" />
        <PostWrapper>
          <Content>{parsedContent}</Content>
        </PostWrapper>
        <Gap gap="60px" mobileGap="32px" />
      </Section>
    </>
  )
}

export default PageContent
