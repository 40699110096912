import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { up } from '../lib/styles'

export const TextComponent = styled.div`
  ${({
    theme: { colors, fonts },
    fontSize = '16px',
    fontWeight = 400,
    lineHeight,

    white,
    gray,
    black,
    red,

    upperCase,
    letterSpacing,
    maxWidth,
    mobileFontSize,
    mobileLineHeight,
    textAlign = 'left',
    mobileTextAlign,
    bp = 'mobile',
    noWrap,

    color,
    uppercase,
  }) => css`
    font-weight: ${fontWeight};
    color: ${color || colors.textWhite};
    font-family: ${fonts.hind};
    text-align: ${mobileTextAlign || textAlign};

    color: ${white && colors.textWhite};
    color: ${gray && colors.textGray};
    color: ${black && colors.textBlack};
    color: ${red && colors.red};

    white-space: ${noWrap && 'nowrap'};
    text-transform: ${upperCase && 'uppercase'};
    letter-spacing: ${letterSpacing};
    max-width: ${maxWidth};

    font-size: ${mobileFontSize || fontSize};
    line-height: ${mobileLineHeight || lineHeight};

    ${uppercase && css`
      text-transform: uppercase;
    `}

    ${up(bp)} {
      font-size: ${fontSize};
      line-height: ${lineHeight};
      text-align: ${textAlign};
    }
  `}
`

const Text = props => (
  <TextComponent
    gray
    fontSize="24px"
    lineHeight="33px"
    fontWeight="700"
    mobileFontSize="20px"
    mobileLineHeight="28px"
    {...props}
  />
)

Text.Large = props => (
  <TextComponent
    gray
    fontSize="42px"
    lineHeight="48px"
    fontWeight="700"
    mobileFontSize="24px"
    mobileLineHeight="33px"
    {...props}
  />
)

Text.Small = props => (
  <TextComponent
    black
    fontSize="18px"
    // lineHeight="18px"
    fontWeight="400"
    // mobileFontSize="24px"
    // mobileLineHeight="33px"
    {...props}
  />
)

Text.Contact = props => (
  <TextComponent
    white
    fontSize="24px"
    fontWeight="400"
    textAlign="center"
    // mobileFontSize="24px"
    // mobileLineHeight="33px"
    {...props}
  />
)

Text.UseCaseDescription = props => (
  <TextComponent
    white
    fontSize="16px"
    fontWeight="700"
    textAlign="center"
    // mobileFontSize="24px"
    // mobileLineHeight="33px"
    {...props}
  />
)

Text.Usage = React.forwardRef((props, ref) => (
  <TextComponent
    fontSize="18px"
    lineHeight="30px"
    fontWeight="400"
    white
    mobileFontSize="16px"
    mobileLineHeight="unset"
    ref={ref}
    {...props}
  />
))

Text.Error = styled(props => (
  <TextComponent
    fontSize="20px"
    fontWeight="400"
    red
    {...props}
  />
))`
  position: absolute;
  bottom: -25px;
`

Text.ErrorInvest = styled(props => (
  <TextComponent
    fontSize="16px"
    fontWeight="400"
    color="#ca2b2b"
    {...props}
  />
))`
 position: absolute;
  bottom: -20px;
`

const UsageSchedule = styled(Text.Usage).attrs(() => ({
  mobileFontSize: '16px',
}))`
  ${({ theme: { colors }, isZuri }) => css`
    /* color: ${isZuri ? colors.usageYellow : colors.usageBlue}; */
    color: rgba(100,100,100, 0.8);
    margin-bottom: 10px;
    line-height: unset;
  `}
`

Text.UsageSchedule = React.forwardRef((props, ref) => (
  // <div>
  <UsageSchedule {...props} ref={ref} />
  // </div>
))

Text.Black = styled.span`
  color: ${({ theme }) => theme.colors.textBlack};
`

// Text.Black = ({ children, ...props }) => (
//   <TextComponent
//     black
//     {...props}
//   >
//     {children}
//   </TextComponent>
// )

export default Text

/**
 menu,            white, 19, 19, 400, uppercase

1 - hp

--- Velký text        gray, 42, 48, 700 - Text.Large
--- Malý text         gray, 24, 33, 700 - Text
max range text    white, 24, 24, 700, uppercase
jobs              white, 36, 36, 700 - Title.Medium
jobs title        white, 48, 48, 700  - Title.Large

2 - technical
Top texty         white, 24, 24, 700, uppercase
title             black, 42, 90, 700, uppercase   Title.Big
--- texty             gray, 24, 33, 700 - Text

3 - story
datumy - nadpisy  black, 110, 110, 300    Title.Story
podnadpis         black, 36, 36, 400, uppercase Title.StorySub
text              black, 18, 18, 400

4 - usage
titles            white, 30, 30, 700, uppercasee
text              white, 18, 30, 400

5 - career
titles            white, 48, 48, 700, uppercase   Title.Large

6 - contact
  title - 48, 700     Title.Large
  text- 24, 400
*/
