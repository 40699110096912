import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import Col from './Col'
import Button from './Button'
import Link from './Link'
import Title from './Title'
import SectionTest from './Section'
import ImageOverlay from './ImageOverlay'

import { up, addPagePadding, addResponsivity, isTablet } from '../lib/styles'

import {
  Prague as PragueEl,
  Madrid as MadridEl,
  Stockholm as StockholmEl,
  Roma as RomaEl,
  London as LondonEl,
  Berlin as BerlinEl,
  Brusel as BruselEl,
  Paris as ParisEl,
  Zurich as ZurichEl,
  Monaco as MonacoEl,
  Amsterdam as AmsterdamEl,
} from './ellipses'

const EARTH_BASE_WIDTH = 1920

const earthConfig = [
  {
    bp: 0,
    width: 750,
    left: -220,
  },
  {
    bp: 370,
    width: 830,
    left: -220,
  },
  {
    bp: 400,
    width: 900,
    left: -250,
  },
  {
    bp: 450,
    width: 950,
    left: -250,
  },
  {
    bp: 500,
    width: 1100,
    left: -300,
  },
  {
    bp: 550,
    width: 1200,
    left: -300,
  },
  {
    bp: 650,
    width: 850,
    left: 0,
  },
  {
    bp: 850,
    width: 1150,
    left: 0,
  },
  {
    bp: 1150,
    width: 1500,
    left: 0,
  },
  {
    bp: 1500,
    width: 1920,
    left: 0,
  },
]

const Wrapper = styled.div`
  position: relative;
`

const ButtonWrapper = styled(Col)`
  bottom: 25px;
  ${addPagePadding()}

  ${up('tablet')} {
    bottom: 70px;
  }
`

const earthImageStyle = earthConfig.reduce(
  (result, { bp, width, left }) => `
  ${result}
  ${up(bp)} { 
    width: ${width}px; 
    left: ${left}px;
   }`,
  '',
)

const EarthImage = styled(Img)`
  ${earthImageStyle}
`

const Text = styled.span`
  ${({ theme: { fonts, colors } }) => css`
    color: ${colors.yellow};
    font-family: ${fonts.hind};
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
  `}
`

const Circle = styled.div`
  ${({ theme: { colors } }) => css`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${colors.yellow};
    display: inline-block;
    margin-right: 8px;
  `}
`

// position of dot in city wrapper
// needed for proper calculations of positions
const cityOfset = {
  top: 28,
  left: 25,
}

const getEllipseHoverStyle = ({ ellipseOfsetTop, ellipseOfsetLeft }) => earthConfig.reduce(
  (result, { bp, width }) => `
  ${result}
  ${up(bp)} { 
    transform: translate(
      calc(${cityOfset.left + ellipseOfsetLeft * (width / EARTH_BASE_WIDTH)}px - 50%), 
      calc(${cityOfset.top + ellipseOfsetTop * (width / EARTH_BASE_WIDTH)}px - 50%)) 
      scale(${width / EARTH_BASE_WIDTH}); }
      `,
  '',
)

const getEllipseStyle = (scale) => earthConfig.reduce(
  (result, { bp, width }) => `
  ${result}
  ${up(bp)} { 
     path {
       stroke-width: ${2 * (1 / scale) * (EARTH_BASE_WIDTH / width)};
     } }
      `,
  '',
)

const CityWrapper = styled.div`
  ${({
    ellipseOfsetTop,
    ellipseOfsetLeft,
    alternativeEllipseOfsetTop,
    alternativeEllipseOfsetLeft,
    isOpen,
    scale,
    alternativeScale,
  }) => css`
    position: absolute;
    cursor: pointer;
    padding: 20px;

    ${addResponsivity()}

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    svg,
    img {
      pointer-events: none;

      position: absolute;
      top: 0;
      left: 0;
      transform: translate(calc(${cityOfset.left}px - 50%), calc(${
  cityOfset.top
}px - 50%)) scale(0);
      
      ${getEllipseStyle(scale)}

      ${isOpen && getEllipseHoverStyle({ ellipseOfsetTop, ellipseOfsetLeft })}  
        
      ${up('tablet')} {
        transition: 0.5s;
      }

 
    }

    svg.alternativeEllipse {
      ${getEllipseStyle(alternativeScale)}
      ${isOpen
        && getEllipseHoverStyle({
          ellipseOfsetTop: alternativeEllipseOfsetTop,
          ellipseOfsetLeft: alternativeEllipseOfsetLeft,
        })}  
    }

    &:hover {
      svg,
      img {
        ${getEllipseHoverStyle({ ellipseOfsetTop, ellipseOfsetLeft })}
      }
      svg.alternativeEllipse {
      ${getEllipseHoverStyle({
    ellipseOfsetTop: alternativeEllipseOfsetTop,
    ellipseOfsetLeft: alternativeEllipseOfsetLeft,
  })}  
    }
    }

  `}
`

const ORIGINAL_RANGE = 700
const getElipseScale = (range) => range / ORIGINAL_RANGE
const transformCityProps = (props) => {
  const { range, alternativeRange, ellipseOfsetTop, ellipseOfsetLeft } = props
  const scale = getElipseScale(range)
  const alternativeScale = getElipseScale(alternativeRange)
  return {
    ...props,
    ellipseOfsetTop: ellipseOfsetTop * scale,
    ellipseOfsetLeft: ellipseOfsetLeft * scale,
    alternativeEllipseOfsetTop: ellipseOfsetTop * alternativeScale,
    alternativeEllipseOfsetLeft: ellipseOfsetLeft * alternativeScale,
    scale,
    alternativeScale,
  }
}

const getEllipseProps = (range) => ({ scale: getElipseScale(range) })

const City = ({ text, img, alternativeImg, ...rest }) => (
  <CityWrapper {...transformCityProps(rest)}>
    <Circle />
    <Text>{text}</Text>
    {rest.range && img}
    {rest.alternativeRange && alternativeImg}
  </CityWrapper>
)

const getCityPosition = ({ top: fromTop, left: fromLeft }) => earthConfig.reduce(
  (result, { bp, width, left }) => `${result}
  ${up(bp)} { 
    top: ${getTop(width, fromTop)}px; 
    left: ${getLeft(width, fromLeft, left)}px; 
  }`,
  '',
)

const getLeft = (width, fromLeft, leftOfset) => ((fromLeft + leftOfset * (EARTH_BASE_WIDTH / width) + cityOfset.left) / EARTH_BASE_WIDTH)
    * width
  - cityOfset.left
const getTop = (width, fromTop) => ((fromTop + cityOfset.top) / EARTH_BASE_WIDTH) * width - cityOfset.top

const Prague = styled(City)`
  ${getCityPosition({ top: 483, left: 1058 })}
`
const Stockholm = styled(City)`
  ${getCityPosition({ top: 265, left: 1109 })}
`
const Madrid = styled(City)`
  ${getCityPosition({ top: 842, left: 322 })}
`
const Roma = styled(City)`
  ${getCityPosition({ top: 834, left: 1093 })}
`
const London = styled(City)`
  ${getCityPosition({ top: 437, left: 622 })}
`
const Berlin = styled(City)`
  ${getCityPosition({ top: 405, left: 1030 })}
`
const Brusel = styled(City)`
  ${getCityPosition({ top: 462, left: 740 })}
`
const Zurich = styled(City)`
  ${getCityPosition({ top: 570, left: 852 })}
`
const Paris = styled(City)`
  ${getCityPosition({ top: 506, left: 663 })}
`
const Monaco = styled(City)`
  ${getCityPosition({ top: 710, left: 825 })}
`
const Amsterdam = styled(City)`
  ${getCityPosition({ top: 410, left: 770 })}
`

const MaxRangeSection = styled(SectionTest)`
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  ${addPagePadding()}

  top: 30px;
  ${up(649)} {
    top: 50px;
  }
  ${up(849)} {
    top: 100px;
  }
`

// const DEFAULT_RANGE = 700 // original
const DEFAULT_RANGE = 720

const InlineHeader = (props) => <Title.Small as="h2" style={{ display: 'inline' }} uppercase {...props} />

const alternativeEllipseProps = {
  white: true,
  className: 'alternativeEllipse',
}
class Earth extends React.Component {
  timeoutId = null

  alternativeEllipseTimeoutId = null

  constructor(props) {
    super(props)
    this.state = {
      isOpen: true,
      isVisible: true,
      alternativeRange: null,
      showDefaultRange: true,
    }
  }

  onKeyDown = (e) => {
    // console.log('KEY DOWN', e.key, e)
    let { alternativeRange, showDefaultRange } = this.state
    switch (e.code) {
      case 'AltRight': {
        if (this.alternativeEllipseTimeoutId) clearTimeout(this.alternativeEllipseTimeoutId)
        alternativeRange = 100
        showDefaultRange = true
        break
      }
      case 'ShiftRight': {
        if (this.alternativeEllipseTimeoutId) clearTimeout(this.alternativeEllipseTimeoutId)
        alternativeRange = 100
        showDefaultRange = false
        break
      }
      case 'ControlRight': {
        if (this.alternativeEllipseTimeoutId) clearTimeout(this.alternativeEllipseTimeoutId)
        alternativeRange = 250
        showDefaultRange = false
        break
      }
      default:
    }
    this.setState({ alternativeRange, showDefaultRange })
  }

  onKeyUp = (e) => {
    // console.log('KEY UP', e.key)
    if (['AltRight', 'ShiftRight', 'ControlRight'].includes(e.code)) {
      this.alternativeEllipseTimeoutId = setTimeout(
        () => this.setState({ alternativeRange: null, showDefaultRange: true }),
        1000,
      )
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  }

  componentWillUnmount() {
    if (this.timeoutId) clearTimeout(this.timeoutId)
    window.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('keyup', this.onKeyUp)
  }

  cityOnMouseOver = () => {
    this.setState({ isOpen: false })
    if (this.timeoutId) clearTimeout(this.timeoutId)
  }

  cityOnMouseLeave = () => {
    this.timeoutId = setTimeout(() => {
      this.setState({ isOpen: true })
    }, 2000)
  }

  wrapperOnMouseOver = () => {
    const windowWidth = window && window.innerWidth
    if (!isTablet(windowWidth)) {
      this.setState({ isVisible: false })
    }
  }

  wrapperOnMouseLeave = () => {
    const windowWidth = window && window.innerWidth
    if (!isTablet(windowWidth)) {
      this.setState({ isVisible: true })
    }
  }

  render() {
    const { fluid } = this.props
    const { isOpen, isVisible, showDefaultRange, alternativeRange } = this.state

    const cityProps = {
      onMouseOver: this.cityOnMouseOver,

      onMouseLeave: this.cityOnMouseLeave,

      range: showDefaultRange ? DEFAULT_RANGE : 0,
      alternativeRange,
    }
    return (
      <Wrapper onMouseEnter={this.wrapperOnMouseOver} onMouseLeave={this.wrapperOnMouseLeave}>
        <ImageOverlay isControllable isVisible={isVisible} />
        <MaxRangeSection background="transparent">
          <Title.Small as="div">
            <InlineHeader>Real-World Range</InlineHeader> 700+ km (plus 30min reserve)
          </Title.Small>
        </MaxRangeSection>
        <Col width="100%" height="100%" position="relative">
          <EarthImage fluid={fluid} />
          <Prague
            text="Prague"
            img={<PragueEl {...getEllipseProps(cityProps.range)} />}
            alternativeImg={(
              <PragueEl
                {...getEllipseProps(cityProps.alternativeRange)}
                {...alternativeEllipseProps}
              />
            )}
            ellipseOfsetTop={25}
            ellipseOfsetLeft={-15}
            isOpen={isOpen}
            {...cityProps}
          />
          <Stockholm
            text="Stockholm"
            img={<StockholmEl {...getEllipseProps(cityProps.range)} />}
            alternativeImg={(
              <StockholmEl
                {...getEllipseProps(cityProps.alternativeRange)}
                {...alternativeEllipseProps}
              />
            )}
            ellipseOfsetTop={20}
            ellipseOfsetLeft={0}
            {...cityProps}
          />
          <Madrid
            text="Madrid"
            img={<MadridEl {...getEllipseProps(cityProps.range)} />}
            alternativeImg={(
              <MadridEl
                {...getEllipseProps(cityProps.alternativeRange)}
                {...alternativeEllipseProps}
              />
            )}
            ellipseOfsetTop={35}
            ellipseOfsetLeft={0}
            {...cityProps}
          />
          <Roma
            text="Rome"
            img={<RomaEl {...getEllipseProps(cityProps.range)} />}
            alternativeImg={(
              <RomaEl
                {...getEllipseProps(cityProps.alternativeRange)}
                {...alternativeEllipseProps}
              />
            )}
            ellipseOfsetTop={25}
            ellipseOfsetLeft={-50}
            {...cityProps}
          />
          <London
            text="London"
            img={<LondonEl {...getEllipseProps(cityProps.range)} />}
            alternativeImg={(
              <LondonEl
                {...getEllipseProps(cityProps.alternativeRange)}
                {...alternativeEllipseProps}
              />
            )}
            ellipseOfsetTop={35}
            ellipseOfsetLeft={-15}
            {...cityProps}
          />
          <Berlin
            text="Berlin"
            img={<BerlinEl {...getEllipseProps(cityProps.range)} />}
            alternativeImg={(
              <BerlinEl
                {...getEllipseProps(cityProps.alternativeRange)}
                {...alternativeEllipseProps}
              />
            )}
            ellipseOfsetTop={39}
            ellipseOfsetLeft={-11}
            {...cityProps}
          />
          <Brusel
            text="Brussels"
            img={<BruselEl {...getEllipseProps(cityProps.range)} />}
            alternativeImg={(
              <BruselEl
                {...getEllipseProps(cityProps.alternativeRange)}
                {...alternativeEllipseProps}
              />
            )}
            ellipseOfsetTop={25}
            ellipseOfsetLeft={10}
            hideBelow="mobile"
            {...cityProps}
          />
          <Amsterdam
            text="Amsterdam"
            img={<AmsterdamEl {...getEllipseProps(cityProps.range)} />}
            alternativeImg={(
              <AmsterdamEl
                {...getEllipseProps(cityProps.alternativeRange)}
                {...alternativeEllipseProps}
              />
            )}
            ellipseOfsetTop={25}
            ellipseOfsetLeft={10}
            hideBelow="mobile"
            {...cityProps}
          />
          <Zurich
            text="Zurich"
            img={<ZurichEl {...getEllipseProps(cityProps.range)} />}
            alternativeImg={(
              <ZurichEl
                {...getEllipseProps(cityProps.alternativeRange)}
                {...alternativeEllipseProps}
              />
            )}
            ellipseOfsetTop={45}
            ellipseOfsetLeft={-3}
            {...cityProps}
          />
          <Paris
            text="Paris"
            img={<ParisEl {...getEllipseProps(cityProps.range)} />}
            alternativeImg={(
              <ParisEl
                {...getEllipseProps(cityProps.alternativeRange)}
                {...alternativeEllipseProps}
              />
            )}
            ellipseOfsetTop={47}
            ellipseOfsetLeft={5}
            {...cityProps}
          />
          <Monaco
            text="Monaco"
            img={<MonacoEl {...getEllipseProps(cityProps.range)} />}
            alternativeImg={(
              <MonacoEl
                {...getEllipseProps(cityProps.alternativeRange)}
                {...alternativeEllipseProps}
              />
            )}
            ellipseOfsetTop={55}
            ellipseOfsetLeft={-25}
            {...cityProps}
          />
        </Col>
        <ButtonWrapper alignItems="center" width="100%" position="absolute">
          <Button.Link to={Link.USES}>MORE ABOUT ZURI USE CASES</Button.Link>
        </ButtonWrapper>
      </Wrapper>
    )
  }
}

export default Earth
