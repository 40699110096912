import React from 'react'
import styled, { css } from 'styled-components'
import axios from 'axios'
import TextField from './TextField'
import Button from './Button'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Text from './Text'
import { up, addPagePadding } from '../lib/styles'

import twitter from '../data/images/social/twitter.svg'
import facebook from '../data/images/social/facebook.svg'
import instagram from '../data/images/social/instagram.svg'
import linkedin from '../data/images/social/linkedin.svg'
import youtube from '../data/images/social/youtube.svg'
import moment from 'moment'

const Wrapper = styled.div`
  min-height: 330px;
  width: 100%;
  background: black;
  ${addPagePadding()}
`

const ThanksText = styled(Text)`
  ${({ theme: { colors } }) => css`
    color: ${colors.textWhite};
    font-size: 28px;
    font-weight: 700;
  `}
`

const SocialLink = styled.a.attrs(() => ({ target: '_blank' }))`
  padding: 0 10px;
  img {
    width: auto;
    height: 30px;
  }
`

const Copyright = styled.span`
  ${({ theme: { colors } }) => css`
    font-size: 12px;
    font-weight: 700;
    color: ${colors.textWhite};
  `}
`

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSent: false,
      email: '',
      errorMessage: '',
    }
  }

  onEmailSend = async () => {
    const { email } = this.state
    if (!email) return this.setState({ errorMessage: 'Email is required' })
    if (!validateEmail(email)) return this.setState({ errorMessage: 'Email is not valid' })
    this.setState({ isSent: true })

    try {
      await axios.post('/api/v1/newsletter', {
        email: email.trim(),
      })
      this.setState({ email: '', errorMessage: '' })
    } catch (error) {
      this.setState({ isSent: false, errorMessage: '' })
      setTimeout(
        // call after ui update
        () => alert('Sending failed'),
        1,
      )
    }
  }

  render() {
    const { email, isSent, errorMessage } = this.state
    return (
      <Wrapper>
        <Gap gap="80px" />
        <Row
          justifyContent="center"
          alignItems="center"
          width="100%"
          responsive
        >
          {!isSent && (
            <Row alignItems="flex-end" responsive maxWidth="100%">
              <Col width="350px" maxWidth="100%">
                <Text as="label" htmlFor="email">
                  Subscribe for Zuri Updates
                </Text>
                <Gap gap="8px" mobileGap="16px" />
                <TextField
                  id="email"
                  value={email}
                  isFooter
                  placeholder="Your Email Address"
                  onChange={({ target }) => this.setState({ email: target.value })}
                  required
                  hasError={!!errorMessage}
                  error={errorMessage}
                  onBlur={() => this.setState({ errorMessage: '' })}
                />
              </Col>
              <Gap gap="15px" mobileGap="16px" />
              <Col width="auto" maxWidth="350px" mobileWidth="100%">
                <Button.SubmitForm onClick={this.onEmailSend}>
                  Sign up
                </Button.SubmitForm>
              </Col>
            </Row>
          )}
          {isSent && (
            <ThanksText>
              Thank you, we will keep you updated as things develop.
            </ThanksText>
          )}
        </Row>
        <Gap gap="55px" />
        <Row justifyContent="center">
          <SocialLink href="https://twitter.com/zurivtol">
            <img src={twitter} alt="twitter logo" />
          </SocialLink>
          <Gap gap="30px" mobileGap="10px" bp="menu" />
          <SocialLink href="https://www.facebook.com/zuriplane/">
            <img src={facebook} alt="facebook logo" />
          </SocialLink>
          <Gap gap="30px" mobileGap="10px" bp="menu" />
          <SocialLink href="https://www.instagram.com/zurivtol/">
            <img src={instagram} alt="instagram logo" />
          </SocialLink>
          <Gap gap="30px" mobileGap="10px" bp="menu" />
          <SocialLink href="https://www.linkedin.com/company/zuri-com/">
            <img src={linkedin} alt="linkedin logo" />
          </SocialLink>
          <Gap gap="30px" mobileGap="10px" bp="menu" />
          <SocialLink href="https://www.youtube.com/channel/UC6IhWqcbqiwM_V2zVlpelFA/videos">
            <img src={youtube} alt="youtube logo" />
          </SocialLink>
        </Row>
        <Gap gap="50px" />
        <Col alignItems="center" justifyContent="center">
          <Copyright>Zuri © {moment().format('YYYY')}</Copyright>
        </Col>
        <Gap gap="65px" />
      </Wrapper>
    )
  }
}

export default Footer
