import { graphql } from 'gatsby'

// eslint-disable-next-line
export const fragments = () => graphql`
    fragment FullHdImage on File {
      image: childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    fragment ArticleImage on File {
      image: childImageSharp {
        fluid(maxWidth: 813, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    fragment JobFragment on Jobs {
      _id
      slug
      lang
      title
      content
      titleCs
      slugCs
      contentCs
    }

    fragment PostFragment on Posts {
      _id
      slug
      title
      content
      contentExtended
      state
      titleImage {
        filename {
          ...ArticleImage
        }
      }
      author
      metaTitle
      metaDescription
      twitterLink
      facebookLink
      instagramLink
      linkedinLink
      youtubeLink
      createdAt
    }

    fragment ArticleFragment on Articles {
      _id
      slug
      title
      content
      contentExtended
      state
      titleImage {
        filename {
          ...ArticleImage
        }
      }
      author
      metaTitle
      metaDescription
      twitterLink
      facebookLink
      instagramLink
      linkedinLink
      youtubeLink
      createdAt
      tags
    }

    fragment PostContentImage on File {
      contentImage: childImageSharp {
        fluid(maxWidth: 812, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `()
