import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Col from './Col'
import Gap from './Gap'
import Text from './Text'
import Section from './Section'
import Title from './Title'
import VH from './VH'
import RH from './RH'
import HpTopCarousel from './HpTopCarousel'

import { up, addPagePadding, theme } from '../lib/styles'

const CAREER_TOP_BP = 'tablet'

const HiddenTitle = styled.h1`
  display: none;
`

const HpTopText = styled(Text)`
  ${({ theme: { colors } }) => css`
    color: ${colors.textWhite};
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    margin: 0;
    padding: 0;

    ${up('mobile')} {
      font-size: 42px;
    }

    ${up(CAREER_TOP_BP)} {
      text-align: left;
    }
  `}
`

const Content = styled.div`
  z-index: 1;
  pointer-events: none;
  position: absolute;

  bottom: 0;
  left: 0;

  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  padding: 40px 8px 40px 8px;

  height: ${theme.hpTopSectionMobileHeight};
  max-height: ${theme.hpTopSectionMaxHeight};

  ${up(CAREER_TOP_BP)} {
    /* top: 50%; */

    height: 100vh;
    display: flex;
    padding-bottom: 80px;
    justify-content: space-between;

    padding-left: 96px;
  }
`

class HpTop extends Component {
  render() {
    const { images, lang } = this.props

    const title =
      lang === 'cs'
        ? 'Kariéra v Zuri'
        : 'Join the Zuri Team'

    return (
      <>
        <Section.FullHeightTop>
          <HpTopCarousel images={images} videoHidden />

          <Content>
            <Col />

            <Col>
              <HpTopText as="h1">{title}</HpTopText>
            </Col>
          </Content>
        </Section.FullHeightTop>
      </>
    )
  }
}

export default HpTop
