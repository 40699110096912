import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import { TweenLite, TimelineMax, Linear, Power1 } from 'gsap'
import { Waypoint } from 'react-waypoint'

import {
  createZuriAnimation,
  createPlaneAnimation,
  createHelicopterAnimation,
  createPersonAnimation,
  createCarAnimation,
  createBoatAnimation,
  getBgImageStyle,
  getAnimationStyle,
  applyTimeCoef,
  createTextAnimation,
} from './helpers'
import CityDescription from './CityDescription'
import {
  ZuriBeachShadow,
  ZuriBeach,
  ZuriBeachPath,
  CarBeachPath,
  PlaneBeachPath,
  Person,
  PlaneBeach,
  PlaneBeachShadow,
  Boat,
  CarBeach,
} from './images'
import Content from './Content'
import Title from '../Title'
import Text from '../Text'
import Button from '../Button'

import { up } from '../../lib/styles'

import Col from '../Col'
import Row from '../Row'
import Gap from '../Gap'

const animBase = {
  top: 352,
  left: 968,
}

const defaultImageWidth = 1920

const animationConfig = [
  {
    bp: 0,
    width: 980,
    left: -340,
  },
  {
    bp: 380,
    width: 980,
    left: -280,
  },
  {
    bp: 700,
    width: 980,
    left: 0,
  },
  {
    bp: 980,
    width: 1500,
    left: 0,
  },
  {
    bp: 1100,
    width: 1650,
    left: 0,
  },
  {
    bp: 1260,
    width: 1920,
    left: 0,
  },
]

const BgWrapper = styled(Img)`
  height: auto;
  ${getBgImageStyle(animationConfig)}
`
const AnimationWrapper = styled.div`
  ${getAnimationStyle({
    animationConfig,
    defaultImageWidth,
    animBase,
  })}
`

const ContentBox = ({ zuriSchedule, carSchedule, planeSchedule, ...props }) => (
  <Content.Box maxWidth="unset" {...props}>
    <Content.ScheduleCol>
      <Title.UsageBox isZuri>ZURI 2:40 h</Title.UsageBox>
      <Gap gap="40px" mobileGap="20px" />
      {zuriSchedule.map(({ text, ref }, key) => (
        <Text.UsageSchedule ref={ref} isZuri key={key}>
          {text}
        </Text.UsageSchedule>
      ))}
    </Content.ScheduleCol>
    <Gap gap="10px" mobileGap="10px" />
    <Content.ScheduleCol>
      <Title.UsageBox>CAR AND BOAT 12:05 h</Title.UsageBox>
      <Gap gap="40px" mobileGap="20px" />
      {carSchedule.map(({ text, ref }, key) => (
        <Text.UsageSchedule ref={ref} key={key}>
          {text}
        </Text.UsageSchedule>
      ))}
    </Content.ScheduleCol>
    <Gap gap="10px" mobileGap="10px" hideBelow={350} />
    <Content.ScheduleCol>
      <Title.UsageBox>AIRLINER 8:05 h</Title.UsageBox>
      <Gap gap="40px" mobileGap="20px" />
      {planeSchedule.map(({ text, ref }, key) => (
        <Text.UsageSchedule ref={ref} key={key}>
          {text}
        </Text.UsageSchedule>
      ))}
    </Content.ScheduleCol>
  </Content.Box>
)

const TIME_COEF = 4

class BeachSection extends Component {
  constructor(props) {
    super(props)
    this.carRef = React.createRef()
    this.car2Ref = React.createRef()
    this.carBoatRef = React.createRef()
    this.carPersonRef = React.createRef()

    this.zuriRef = React.createRef()
    this.zuriShadowRef = React.createRef()
    this.personPragueRef = React.createRef()

    this.planeRef = React.createRef()
    this.planeShadowRef = React.createRef()
    this.planeBoatRef = React.createRef()
    this.planeCarRef = React.createRef()
    this.planePersonRef = React.createRef()
    this.planeCarRef = React.createRef()

    this.zuriSchedule = [
      { text: '0:15  Travel to vertiport', time: 1 / 4 },
      { text: '0:15  Zuri onboarding', time: 1 / 4 },
      { text: '2:00  Zuri flight', time: 2 },
      { text: '0:10  Offboarding', time: 1 / 6 },
    ]
    this.zuriSchedule = applyTimeCoef(this.zuriSchedule, TIME_COEF)
    this.zuriSchedule.forEach(el => {
      el.ref = React.createRef()
    })

    this.carSchedule = [
      { text: '9:45  Drive from Prague to Zagorije', time: 9 + 3 / 4 },
      { text: '0:45  Waiting for ferry', time: 3 / 4 },
      { text: '0:45  Ferry from Zagorje to Porozina', time: 3 / 4 },
      { text: '0:50  Drive to destination', time: 5 / 6 },
    ]
    this.carSchedule = applyTimeCoef(this.carSchedule, TIME_COEF)
    this.carSchedule.forEach(el => {
      el.ref = React.createRef()
    })
    this.planeSchedule = [
      { text: '0:35  Travel to an airport', time: 7 / 12 },
      { text: '0:50  Airport: checks and waiting', time: 5 / 6 },
      { text: '3:30  Flight', time: 3 + 1 / 2 },
      { text: '0:40  Offboarding and baggage', time: 2 / 3 },
      { text: '0:45  Waiting for ferry', time: 3 / 4 },
      { text: '0:45  Ferry from Pula to Porozina', time: 3 / 4 },
      { text: '0:50  Taxi to destination', time: 5 / 6 },
    ]
    this.planeSchedule = applyTimeCoef(this.planeSchedule, TIME_COEF)
    this.planeSchedule.forEach(el => {
      el.ref = React.createRef()
    })

    this.zuriScheduleMobile = this.zuriSchedule.map(el => ({
      ...el,
      ref: React.createRef(),
    }))
    this.planeScheduleMobile = this.planeSchedule.map(el => ({
      ...el,
      ref: React.createRef(),
    }))
    this.carScheduleMobile = this.carSchedule.map(el => ({
      ...el,
      ref: React.createRef(),
    }))

    this.state = {
      wasPlayed: false,
    }
  }

  zuriAnimation = () => {
    const zuriTimeline = createZuriAnimation({
      elementRef: this.zuriRef,
      shadowRef: this.zuriShadowRef,
      path: [
        { top: 200, left: -54 },
        { top: 532, left: -44 },
      ],
      duration: this.zuriSchedule[2].time,
      delay: this.zuriSchedule[0].time + this.zuriSchedule[1].time,
      takeoffTime: 2,
    })

    const textTimeline = createTextAnimation({
      schedule: this.zuriSchedule,
      isZuri: true,
    })
    const mobileTextTimeline = createTextAnimation({
      schedule: this.zuriScheduleMobile,
      isZuri: true,
    })

    zuriTimeline.add(textTimeline, 0)
    zuriTimeline.add(mobileTextTimeline, 0)
    return zuriTimeline
  }

  planeAnimation = () => {
    const { planeSchedule } = this

    const person1Timeline = createPersonAnimation({
      elementRef: this.personPragueRef,
      duration: planeSchedule[0].time + planeSchedule[1].time,
    })

    const planeTimeline = createPlaneAnimation({
      elementRef: this.planeRef,
      shadowRef: this.planeShadowRef,
      path: [
        { top: 215, left: -120 },
        { top: 500, left: -135 },
      ],
      duration: planeSchedule[2].time,
    })

    const person2Timeline = createPersonAnimation({
      elementRef: this.planePersonRef,
      duration: planeSchedule[3].time + planeSchedule[4].time,
    })

    const boatTimeline = createBoatAnimation({
      elementRef: this.planeBoatRef,
      path: [{ top: 535, left: -40 }],
      duration: planeSchedule[5].time,
    })

    const carTimeline = createCarAnimation({
      elementRef: this.planeCarRef,
      duration: planeSchedule[6].time,
      isLast: true,
    })

    const timeline = new TimelineMax()
    timeline
      .add(person1Timeline)
      .add(planeTimeline)
      .add(person2Timeline)
      .add(boatTimeline)
      .add(carTimeline)

    const textTimeline = createTextAnimation({
      schedule: this.planeSchedule,
      isZuri: false,
    })
    const mobileTextTimeline = createTextAnimation({
      schedule: this.planeScheduleMobile,
      isZuri: false,
    })

    timeline.add(textTimeline, 0)
    timeline.add(mobileTextTimeline, 0)
    return timeline
  }

  carAnimation = () => {
    const { carSchedule } = this

    const carTimeline = createCarAnimation({
      elementRef: this.carRef,
      path: [
        { top: 5, left: 10 },
        { top: 33, left: 0 },
        { top: 50, left: 15 },
        { top: 70, left: 15 },
        { top: 120, left: -40 },
        { top: 130, left: -90 },
        { top: 160, left: -150 },
        { top: 160, left: -210 },
        { top: 230, left: -160 },
        { top: 305, left: -85 },
        { top: 330, left: -25 },
        { top: 360, left: -5 },
        { top: 395, left: -70 },
        { top: 420, left: -35 },
        { top: 460, left: -25 },
        { top: 495, left: -50 },
      ],
      duration: carSchedule[0].time,
    })

    const carWaitTimeline = createCarAnimation({
      elementRef: this.carRef,
      duration: carSchedule[1].time,
    })

    const boatTimeline = createBoatAnimation({
      elementRef: this.carBoatRef,
      path: [{ top: 500, left: -40 }],
      duration: carSchedule[2].time,
    })

    const car2Timeline = createCarAnimation({
      elementRef: this.car2Ref,
      path: [
        { top: 540, left: -5 },
        { top: 550, left: -10 },
      ],
      duration: carSchedule[3].time,
      isLast: true,
    })

    const timeline = new TimelineMax()
    timeline
      .add(carTimeline)
      .add(carWaitTimeline)
      .add(boatTimeline)
      .add(car2Timeline)

    const textTimeline = createTextAnimation({
      schedule: this.carSchedule,
      isZuri: false,
    })
    const mobileTextTimeline = createTextAnimation({
      schedule: this.carScheduleMobile,
      isZuri: false,
    })

    timeline.add(textTimeline, 0)
    timeline.add(mobileTextTimeline, 0)
    return timeline
  }

  startAnimation = () => {
    if (!this.wasOncePlayed) {
      this.wasOncePlayed = true
      this.createAnimation()
    }
  }

  createAnimation = () => {
    this.timeline = new TimelineMax({
      onStart: () => {
        this.setState({ wasPlayed: false })
      },
      onComplete: () => {
        this.setState({ wasPlayed: true })
      },
    })
    this.timeline
      .add(this.zuriAnimation(), 0)
      .add(this.planeAnimation(), 0)
      .add(this.carAnimation(), 0)
  }

  restart = () => {
    if (this.timeline) {
      this.timeline.restart()
    }
  }

  render() {
    const { bgImage } = this.props
    const { wasPlayed } = this.state
    const zuriPosition = { top: '-30px', left: '-50px' }
    const planePosition = { top: '-35px', left: '-50px' }

    return (
      <Col position="relative">
        <Col position="relative">
          <BgWrapper fluid={bgImage.image.fluid} />
          <Button.Replay onClick={this.restart} show={wasPlayed} />
        </Col>
        {/* <Gap gap="320px" showBelow="tablet" /> */}
        {/* <Gap gap="100px" showBelow={465} /> */}

        <AnimationWrapper>
          <Waypoint bottomOffset="50%" onEnter={this.startAnimation} />

          <ZuriBeachPath />
          <CarBeachPath style={{ left: '-190px' }} />
          <PlaneBeachPath style={{ left: '-80px' }} />

          <CityDescription top={-80} left={5}>
            Prague / Home
          </CityDescription>
          <CityDescription top={483} left={7}>
            Cres / Hotel
          </CityDescription>

          <ZuriBeachShadow ref={this.zuriShadowRef} position={zuriPosition} />
          <ZuriBeach ref={this.zuriRef} position={zuriPosition} />

          <PlaneBeachShadow
            ref={this.planeShadowRef}
            position={planePosition}
          />
          <PlaneBeach ref={this.planeRef} position={planePosition} />

          <Person
            ref={this.personPragueRef}
            position={{ top: '-25px', left: '-5px' }}
          />
          <Person
            ref={this.planePersonRef}
            position={{ top: '520px', left: '-93px' }}
          />
          <Person
            ref={this.carPersonRefRef}
            position={{ top: '480px', left: '-35px' }}
          />

          <Boat
            ref={this.carBoatRef}
            position={{ top: '480px', left: '-65px' }}
          />
          <Boat
            ref={this.planeBoatRef}
            position={{ top: '520px', left: '-123px' }}
          />

          <CarBeach
            ref={this.planeCarRef}
            position={{ top: '555px', left: '-20px' }}
          />

          <CarBeach
            ref={this.carRef}
            position={{ top: '-20px', left: '-20px' }}
          />

          <CarBeach
            ref={this.car2Ref}
            position={{ top: '515px', left: '-15px' }}
          />
        </AnimationWrapper>
        <Content top="-50px">
          <Content.TextWrapper>
            <Col maxWidth="515px">
              <Title.Usage as="h3">Faster to the Beach: 550 KM in Just 2 Hours</Title.Usage>
              <Gap gap="38px" mobileGap="20px" bp="tablet" />
              <Text.Usage>
                When travel to a sea resort takes only two hours, why not have a
                mini vacation multiple times per year?
              </Text.Usage>
            </Col>
          </Content.TextWrapper>
          <Gap gap="80px" />
          <ContentBox
            hideBelow="tablet"
            zuriSchedule={this.zuriSchedule}
            planeSchedule={this.planeSchedule}
            carSchedule={this.carSchedule}
          />
        </Content>
        <ContentBox
          showBelow="tablet"
          zuriSchedule={this.zuriScheduleMobile}
          planeSchedule={this.planeScheduleMobile}
          carSchedule={this.carScheduleMobile}
        />
      </Col>
    )
  }
}

export default BeachSection
